import React from 'react';
import Header from '../../Components/Header';

const Contact = () => {
  return (
    <div className="height">
      <Header />
      <div className="container">
        <div className="title">Contact</div>
      </div>
    </div>
  )
};

export default Contact;